<template>
  <v-select
    v-model="selectedMu"
    placeholder="Pilih Lokasi Mobil Unit"
    :options="formattedOptions"
    label="text"
    :reduce="(option) => option.value"
    @input="handleInput"
  />
</template>

<script>
import vSelect from 'vue-select'
import manageBloodvanAPI from '../../api/managebloodvan/manageBloodvanAPI'

export default {
  name: 'RegisteredMULists',
  components: { vSelect },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedMu: this.value,
      allRegisteredMU: [],
    }
  },
  computed: {
    formattedOptions() {
      return this.allRegisteredMU.map(v => ({
        label: v.name,
        text: v.name,
        value: v,
      }))
    },
  },
  watch: {
    value(newValue) {
      this.selectedMu = newValue
      this.saveState()
    },
  },
  mounted() {
    this.loadState()
    this.fetchRegisteredMU()
  },
  methods: {
    async fetchRegisteredMU() {
      try {
        const response = await manageBloodvanAPI.getAll({ per_page: 100 })
        const { data } = response.data.data

        if (Array.isArray(data)) {
          // Prepend the default option to the fetched data
          this.allRegisteredMU = [
            { id: null, name: 'Pilih Lokasi MU', type: null },
            {
              id: 1, hospital_id: 1, name: 'RSUD OTO ISKANDAR DI NATA', type: 'hospital',
            },
            ...data.map(item => ({
              ...item,
              hospital_id: null,
              type: 'blood_van',
            })),
          ]
        } else {
          console.log('Expected an array but received: ', data)
          this.allRegisteredMU = [{ id: null, name: 'Pilih Lokasi MU', type: null }]
        }
      } catch (error) {
        console.error(error)
        this.allRegisteredMU = [{ id: null, name: 'Pilih Lokasi MU', type: null }]
      }
    },
    handleInput(selectedValue) {
      this.selectedMu = selectedValue
      this.$emit('input', selectedValue)
    },
    saveState() {
      localStorage.setItem('registeredMUState', JSON.stringify({
        selectedMu: this.selectedMu,
        allRegisteredMU: this.allRegisteredMU,
      }))
    },
    loadState() {
      const savedState = localStorage.getItem('registeredMUState')

      if (savedState) {
        const { selectedMu, allRegisteredMU } = JSON.parse(savedState)
        this.selectedMu = selectedMu
        this.allRegisteredMU = allRegisteredMU
        this.$emit('input', selectedMu)
      }
    },
  },
}
</script>

<style scoped>

</style>
